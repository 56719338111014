import type { Dispatch, FocusEvent, JSX, SetStateAction } from 'react';
import React from 'react';

import type { Form } from '@feathr/blackbox';
import { Button } from '@feathr/components';
import { cssVar } from '@feathr/hooks';

interface IProps {
  form: Form;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
}

export default function SubmitButton({ form, setIsFocused }: Readonly<IProps>): JSX.Element {
  const {
    submitLabel: label = 'Submit',
    buttonShape: shape = 'rounded',
    submitButtonColor: backgroundColor = cssVar('--color-action'),
    submitButtonTextColor: color = cssVar('--color-white'),
  } = form.formConfig.settings;

  function handleClick(): void {
    setIsFocused(true);
  }

  function handleBlur(event: FocusEvent<HTMLButtonElement>): void {
    if (event.relatedTarget === null) {
      setIsFocused(false);
    }
  }

  return (
    <Button
      buttonShape={shape}
      onBlur={handleBlur}
      onClick={handleClick}
      style={{ backgroundColor, color }}
    >
      {label}
    </Button>
  );
}
